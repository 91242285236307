import Cookies from 'js-cookie';
import { log } from '../infra/log';

export const setCookie = (key: string, value: string, expires = 365 * 5) => {
  Cookies.set(key, value, { expires });
};

export const getCookie = (key: string) => {
  return Cookies.get(key);
};

export function getTuneCookie() {
  return getCookieValueByPredicate((name) => name.startsWith('tdl_'));
}

export function getGoogleSessionCookie() {
  return getCookieValueByPredicate((name) => name.startsWith('_ga_'));
}

export type VisitorTrafficType = 'default' | 'on-the-snow' | 'j2ski';

export const getVisitorType: () => VisitorTrafficType = () => {
  const visitorCookie = getCookie('visitor_data');
  if (!visitorCookie) {
    return 'default';
  }

  try {
    const visitorData = JSON.parse(visitorCookie);
    return visitorData?.visitor_traffic_type || 'default';
  } catch (error) {
    log.error('Error getting visitor traffic type from cookie on client side', { error });
    return 'default';
  }
};

function getCookieValueByPredicate(predicate: (name: string) => boolean) {
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    cookie = cookie.trim();
    if (predicate(cookie)) {
      return cookie.substring(cookie.indexOf('=') + 1);
    }
  }
  return undefined;
}
