import { inspect } from 'util';
import { LogLevel, Logger } from './base';
import { DateTime } from 'luxon';
import chalk, { Chalk } from 'chalk';

const colorByLogLevel: Record<LogLevel, Chalk> = {
  info: chalk.green,
  error: chalk.red,
  warn: chalk.yellow,
  critical: chalk.red,
  debug: chalk.cyan,
};

const serviceName = process.env.NX_TASK_TARGET_PROJECT || process.env.SERVICE_NAME;

export const consoleLogger: Logger = {
  log: function (level: LogLevel, message: string, data?: any): void {
    const color = colorByLogLevel[level];
    console.log(
      `[ ${serviceName ? `${chalk.blue(serviceName)} ] ` : ''}${color(
        `${DateTime.now().toFormat('TT.SSS')} | ${level}`
      )} ${message} ${data ? `| ${inspect?.(data)}` : ''}`
    );
  },
};
