import { DataLayerEvent } from './data-layer-events';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/ban-types
    dataLayer?: Object[];
    [key: string]: any;
  }
}

export function useDataLayerEvent() {
  const dataLayer = (window.dataLayer ||= []);

  const pushEvent = (event: DataLayerEvent) => {
    dataLayer.push(event);
  };

  return pushEvent;
}
