const key = 'test-user';

export function isTestUser() {
  return localStorage.getItem(key) === 'true';
}

export function setTestUserFromUrlParams() {
  const params = new URLSearchParams(window.location.search);
  const testUser = params.get(key);
  if (testUser) {
    localStorage.setItem(key, testUser);
  }
}
