import { UtmType } from '@weski-monorepo/types';

export const getUtmParam = (utmType: UtmType) => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get(utmType);
    return param?.toLowerCase();
  } catch {
    return null;
  }
};

export const getAllUrlParams = () => {
  const params = new URLSearchParams(window.location.search);
  const paramObj: Record<string, string> = {};

  params.forEach((value: string, key: string) => {
    paramObj[key] = value;
  });

  return paramObj;
};

export function parseQueryString(query: string): Record<string, any> {
  const params = new URLSearchParams(query);
  const result: Record<string, any> = {};

  params.forEach((value, key) => {
    try {
      // Attempt to parse JSON values for keys that contain complex data structures
      if (value.startsWith('{') || value.startsWith('[')) {
        result[key] = JSON.parse(decodeURIComponent(value));
      } else {
        result[key] = decodeURIComponent(value);
      }
    } catch (error) {
      // If JSON.parse fails, store the raw value
      result[key] = decodeURIComponent(value);
    }
  });

  return result;
}
