'use client';

import { ExperimentName, Origin, PageContent, TPageName, Variant } from '@weski-monorepo/types';
import { createContext, useContext } from 'react';
import { Domain } from '../utils/get-domain';

export interface GlobalPropsFromSSR {
  pathname: string;
  domain?: Domain;
  currentPage: TPageName;
  pageContent?: PageContent;
  origin?: Origin;
  activeExperiments: Partial<Record<ExperimentName, Variant<ExperimentName>>>;
  isBlackFriday?: boolean;
}

export interface GlobalProps extends GlobalPropsFromSSR {
  getExperimentVariable: <T extends ExperimentName>(experimentName: T) => Variant<T>;
}

export const GlobalPropsContext = createContext<GlobalProps | undefined>(undefined);

export const useGlobalPropsContext = () => {
  const context = useContext(GlobalPropsContext);
  if (!context) {
    throw new Error('useGlobalProps must be used within a GlobalPropsProvider');
  }
  return context;
};
