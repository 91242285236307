import { datadogLogs } from '@datadog/browser-logs';
import { getEnv } from '../../utils/env';

const DATADOG_CLIENT_TOKEN = 'pub019f6f37790c9365a2fd6130298efa4c';

const shouldLogToDatadog = ['staging', 'production'].includes(getEnv());
const isProduction = getEnv() === 'production';

if (typeof window !== 'undefined' && DATADOG_CLIENT_TOKEN) {
  try {
    datadogLogs.init({
      clientToken: DATADOG_CLIENT_TOKEN,
      service: isProduction ? 'weski-client-ssr' : 'weski-client-ssr-dev',
      site: 'datadoghq.eu',
      forwardErrorsToLogs: isProduction,
      sessionSampleRate: 100,
      env: getEnv() || 'staging',
    });
  } catch (error) {
    console.error('Failed to init datadog logs');
  }
}

export const dataDogSessionId = datadogLogs.getInternalContext()?.session_id;

export const dataDogLog =
  shouldLogToDatadog && datadogLogs.logger
    ? datadogLogs.logger
    : {
        info: (message: string, messageContext?: object, error?: Error) => {
          console.log(`Datadog Info: ${message}`, { ...(messageContext || {}), ...(error ? { error } : {}) });
        },
        error: (message: string, messageContext?: object, error?: Error) => {
          console.log(`Datadog Error: ${message}`, { ...(messageContext || {}), ...(error ? { error } : {}) });
        },
        warn: (message: string, messageContext?: object, error?: Error) => {
          console.log(`Datadog Warning: ${message}`, { ...(messageContext || {}), ...(error ? { error } : {}) });
        },
        debug: (message: string, messageContext?: object, error?: Error) => {
          console.log(`Datadog Debug: ${message}`, { ...(messageContext || {}), ...(error ? { error } : {}) });
        },
        critical: (message: string, messageContext?: object, error?: Error) => {
          console.log(`Datadog Critical: ${message}`, { ...(messageContext || {}), ...(error ? { error } : {}) });
        },
      };
