import { useEffect } from 'react';
import { getEnv } from '../../utils/env';
import { useAuth } from '../../auth';
import { getCookie } from '../../utils/cookies';

const DEBUG_INTERCOM = getEnv() === 'development';
const currentPage = {
  name: 'homepage',
  intercomLauncherVisible: true,
};
type UtmType = 'utm_content' | 'utm_medium' | 'utm_source' | 'utm_term' | 'utm_campaign';
const getUtmParam = (utmType: UtmType) => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get(utmType);
    return param?.toLowerCase();
  } catch {
    return null;
  }
};

export const openIntercom = () => {
  DEBUG_INTERCOM && console.log('openIntercom called');
  if (typeof window !== 'undefined' && !!window.Intercom && !!window.Intercom.booted) window.Intercom('showNewMessage');
};

export const logoutIntercom = () => {
  if (typeof window !== 'undefined' && !!window.Intercom && !!window.Intercom.booted) {
    window.Intercom('update', {
      user_hash: null,
      name: null,
      email: null,
      created_at: null,
    });
    DEBUG_INTERCOM && console.log('Removed intercom user props');
  }
};

export const useIntercom = () => {
  const { currentUser: customer } = useAuth();
  const shouldShowLauncher = !!currentPage.intercomLauncherVisible;

  useEffect(() => {
    DEBUG_INTERCOM && console.log('Setting intercom launcher', shouldShowLauncher, customer);
    // eslint-disable-next-line prefer-const
    let intervalId: NodeJS.Timeout;

    const setIntercomLauncher = () => {
      if (typeof window !== 'undefined' && !!window.Intercom && window.Intercom.booted) {
        window.Intercom('update', {
          hide_default_launcher: !shouldShowLauncher,
          user_hash: customer ? customer.intercomUserHmac : null,
          name: customer ? customer.name : null,
          email: customer ? customer.email : null,
          created_at: customer ? customer.created_at : null,
          ab_version: getCookie('ab_channel'),
          lead_utm_campaign: getUtmParam('utm_campaign'),
          lead_utm_content: getUtmParam('utm_content'),
          lead_utm_medium: getUtmParam('utm_medium'),
          lead_utm_source: getUtmParam('utm_source'),
          lead_utm_term: getUtmParam('utm_term'),
        });

        const element = document.querySelector('.intercom-lightweight-app')! as HTMLDivElement;
        if (element) {
          element.style.zIndex = '10000';
        }
        DEBUG_INTERCOM && console.log('Set intercom launcher', shouldShowLauncher, customer);
        clearInterval(intervalId);
      }
    };

    intervalId = setInterval(() => {
      setIntercomLauncher();
    }, 500);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 10000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [shouldShowLauncher, customer]);
};
