'use client';

import { useAuth } from '../../auth';
import { useGlobalPropsContext } from '../../context/global-context';
import { useOrigin } from '../origins/useOrigin';
import { useIsMobile } from '@weski-monorepo/react-components';
import { getUtmParam } from '../../utils/url-params';
import { getCookie, getGoogleSessionCookie, getTuneCookie, getVisitorType, setCookie } from '../../utils/cookies';
import { v4 as uuidv4 } from 'uuid';
import { weskiApi } from '../../api';
import { IAnalyticPayload, IAnalyticsKey } from '@weski-monorepo/types';
import { useEffect, useRef } from 'react';
import { isTestUser } from '../../utils/test-user';

const ANONYMOUS_ID_COOKIE_NAME = 'wetrip_anonymous_id';

const fallbackUserId = uuidv4();

export const useAnalytics = () => {
  const { isAdmin, isLoggedIn, currentUser, isLoading: isAuthLoading } = useAuth();
  const origin = useOrigin();
  const { currentPage, activeExperiments } = useGlobalPropsContext();
  const isMobile = useIsMobile();
  const isDataReady = !isAuthLoading;
  const waitingEvents = useRef<{ eventName: IAnalyticsKey; payload: IAnalyticPayload<any> }[]>([]);

  useEffect(() => {
    if (!isDataReady) {
      return;
    }

    const sendWaitingEvents = async () => {
      await Promise.allSettled(
        waitingEvents.current.map(async (data) => {
          await sendAnalytics(data.eventName, data.payload);
        })
      );

      waitingEvents.current = [];
    };

    void sendWaitingEvents();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataReady]);

  const getMetaData = () => {
    return {
      origin: origin?.id,
      page: currentPage,
      isAdmin,
      loggedIn: isLoggedIn,
      isSSR: true,
      device: isMobile ? 'mobile' : 'desktop',
      'AB Version': getCookie('ab_channel'),
      utm_campaign: getUtmParam('utm_campaign'),
      utm_medium: getUtmParam('utm_medium'),
      utm_source: getUtmParam('utm_source'),
      utm_content: getUtmParam('utm_content'),
      utm_term: getUtmParam('utm_term'),
      visitor_traffic_type: getVisitorType(),
      path: window.location.pathname,
      url: window.location.href,
      experiments: activeExperiments,
    };
  };

  const getUserData = () => {
    return {
      userId: getCookie(ANONYMOUS_ID_COOKIE_NAME) || currentUser?.id?.toString() || fallbackUserId,
      extraReporterData: {
        googleAnalytics: {
          clientId: getCookie('_ga'),
          sessionId: getGoogleSessionCookie(),
        },
        facebook: {
          fbc: getCookie('_fbc'),
          fbp: getCookie('_fbp'),
        },
        tune: {
          transactionId: getTuneCookie(),
        },
      },
      screen: {
        width: window.screen.width,
        height: window.screen.height,
      },
      emailAddress: currentUser?.email,
    };
  };

  async function sendAnalytics<E extends IAnalyticsKey>(eventName: E, payload: IAnalyticPayload<E>) {
    if (!isDataReady) {
      waitingEvents.current.push({ eventName, payload });
      return;
    }
    if (isTestUser()) {
      return;
    }

    const body = {
      event: {
        name: eventName,
        data: {
          ...getMetaData(),
          ...payload,
        },
      },
      userData: getUserData(),
    };
    try {
      await weskiApi.apiClient.post('/events/track', body);
    } catch (error) {
      console.error('error on sendAnalytics', { error, eventName, payload });
    }
  }

  return { sendAnalytics };
};

export function setAnonymousIdCookie() {
  const anonymousClientCookie = getCookie(ANONYMOUS_ID_COOKIE_NAME);
  if (!anonymousClientCookie) {
    setCookie(ANONYMOUS_ID_COOKIE_NAME, uuidv4());
  }
}
