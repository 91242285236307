import { LogLevel, Logger } from './base';
import { consoleLogger } from './console-logger';
import { consoleProdLogger } from './console-prod-logger';
import { dataDogLog } from './data-dog';

const env = process.env.ENV;

const loggers: Logger[] = [
  ...(env === 'development' ? [consoleLogger] : []),
  ...(env === 'staging' ? [consoleProdLogger] : []),
  ...(env === 'production' || process.env.LOG_TO_CLOUDWATCH_ON_DEV === 'true' ? [consoleProdLogger] : []),
];

function doLog(level: LogLevel, message: string, data?: any) {
  if (typeof window !== 'undefined') {
    dataDogLog[level as keyof typeof dataDogLog]?.(message, data);
    return;
  }
  loggers.forEach((logger) => {
    logger.log(level, message, data);
  });
}

function debug(message: string, data?: any) {
  doLog('debug', message, data);
}

function info(message: string, data?: any) {
  doLog('info', message, data);
}

function error(message: string, data?: any) {
  doLog('error', message, data);
}

function warning(message: string, data?: any) {
  doLog('warn', message, data);
}

function critical(message: string, data?: any) {
  doLog('critical', message, data);
}

export const baseLog = {
  debug,
  info,
  error,
  warning,
  critical,
};

export const withDefaults: (options: { defaults?: Record<string, any>; prefix?: string }) => typeof baseLog = (
  options
) => {
  const finalPrefix = options.prefix ? `${options.prefix} ` : ''; // add a space if prefix exists
  const defaultData = options.defaults || {};
  return {
    debug: (m, d) => {
      baseLog.debug(`${finalPrefix}${m}`, { ...defaultData, ...d });
    },
    info: (m, d) => {
      baseLog.info(`${finalPrefix}${m}`, { ...defaultData, ...d });
    },
    warning: (m, d) => {
      baseLog.warning(`${finalPrefix}${m}`, { ...defaultData, ...d });
    },
    error: (m, d) => {
      baseLog.error(`${finalPrefix}${m}`, { ...defaultData, ...d });
    },
    critical: (m, d) => {
      baseLog.critical(`${finalPrefix}${m}`, { ...defaultData, ...d });
    },
  };
};

export const log = {
  ...baseLog,
  doLog,
  withDefaults,
};

export const getErrorFromLog = (data?: any) => {
  if (!data) {
    return {};
  }
  if (data instanceof Error) {
    return {
      error: {
        message: data.message,
        stack: data.stack,
      },
    };
  }

  // search for an Error object in data
  const error = Object.values(data).find((v) => v instanceof Error)!;
  if (error) {
    return {
      error: {
        message: (error as any).message,
        stack: (error as any).stack,
      },
    };
  }

  return {};
};
