import axios from 'axios';
import { Env, getEnv } from '../utils/env';

const urlsForEachEnv: Record<Env, string> = {
  development: 'http://localhost:3000',
  staging: '',
  production: '',
};

export const URL = urlsForEachEnv[getEnv()];
export const apiClient = axios.create({
  baseURL: URL,
  withCredentials: true,
});
