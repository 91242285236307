export type Env = 'development' | 'staging' | 'production';

export function getEnv(): Env {
  if (typeof window === 'undefined') {
    return process.env.ENV as Env;
  }

  if (window.location.host.startsWith('localhost')) {
    return 'development';
  }

  return (process.env.NEXT_PUBLIC_ENV as Env) || 'production';
}
