import axios from 'axios';
import { Env, getEnv } from '../utils/env';
import { getDefaultInternalHeaders } from './default-internal-headers';

const urlsForEachEnv: Record<Env, string> =
  typeof window === 'undefined'
    ? // for ssr requests
      {
        development: 'http://localhost:9775',
        staging: 'https://sandbox-api.weski.com',
        production: 'https://api.weski.com',
      }
    : // for client side requests
      {
        development: 'http://localhost:9775',
        staging: '/api',
        production: '/api',
      };

export const URL = urlsForEachEnv[getEnv()];
export const apiClient = axios.create({
  baseURL: URL,
  withCredentials: true,
});

apiClient.interceptors.request.use((request) => {
  const defaultHeaders = getDefaultInternalHeaders();
  if (defaultHeaders) {
    for (const key in defaultHeaders) {
      request.headers.set(key, defaultHeaders[key as keyof typeof defaultHeaders]);
    }
  }
  return request;
});
