import Cookies from 'js-cookie';
import { dataDogSessionId } from '../infra/log/data-dog';
import { isTestUser } from '../utils/test-user';

export const getDefaultInternalHeaders = () => {
  if (typeof window === 'undefined') return undefined;
  return {
    'X-AB-channel': Cookies.get('ab_channel'),
    'X-Anonymous-Client-Id': Cookies.get('wetrip_anonymous_id'),
    Authorization: `Bearer ${Cookies.get('weskiAuth') || 'none'}`,
    ...(dataDogSessionId ? { 'X-Log-Correlation-Id': dataDogSessionId } : {}),
    ...(isTestUser() ? { 'X-test-user': 'true' } : {}),
  };
};
