import { useQuery } from '@tanstack/react-query';
import { weskiApi, weskiServer } from '../../api';
import { logoutIntercom } from '../../features/intercom/intercom';
import { AdminPermission, AdminRole } from '@weski-monorepo/types';

export interface AuthenticatedUser {
  user?: {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    name: string;
    created_at: string;
    admin: boolean;
    avatar: string;
    intercomUserHmac: string;
    origin: string;
    permissions: AdminPermission[];
    roles: AdminRole[];
  };
}

async function getAuth() {
  const res = await weskiApi.apiClient.get<AuthenticatedUser>('/auth');
  return res.data;
}

async function logout() {
  await weskiServer.apiClient.delete('/users/sign_out');
}

export function useAuth() {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['auth'],
    queryFn: getAuth,
    staleTime: Infinity,
  });

  const currentUser = data?.user;

  const isUserHasPermission = (permission: AdminPermission) => !!currentUser?.permissions.includes(permission);

  return {
    currentUser,
    isLoggedIn: !!currentUser,
    isAdmin: currentUser?.admin,
    isLoading,
    refresh: refetch,
    isUserHasPermission,
    logout: async () => {
      logoutIntercom();
      await logout();
      await refetch();
    },
  };
}
