import { LogLevel, Logger } from './base';
import { getErrorFromLog } from './index';

const env = process.env.ENV;
const service = process.env.SERVICE_NAME;

export const consoleProdLogger: Logger = {
  log: function (level: LogLevel, message: string, data?: any): void {
    const time = new Date().toISOString();
    const record = { message, env, service, level, time, data: data || {} };

    console.log(
      JSON.stringify({
        ...record,
        ...(data.sessionId ? { session_id: data.sessionId } : {}),
        ...(['error', 'critical'].includes(level) ? getErrorFromLog(data) : {}),
      })
    );
  },
};
